function domain()
{
    return process.env.REACT_APP_API_URL;
}
export function publicDomain()
{
    return process.env.REACT_APP_PUBLIC_URL;
}
export function myaccountdomain()
{
    return process.env.REACT_APP_MY_ACCOUNT_URL;
}
export function sidapi()
{
    return domain() + "/api/v1.0/app/core/auth/sid";
}
export function ipapi()
{
    return domain() + "/api/v1.0/app/core/general/ip";
}
export function loginapi()
{
   return domain() + "/api/v1.0/app/core/auth/login";
}
export function country_codeapi()
{
    return domain() + "/api/v1.0/app/core/general/mcc/list/all";
}
export function countryselectapi()
{
    return domain() + "/api/v1.0/app/core/general/country/list/all";
}
export function loginwithotpapi()
{
    return domain() + '/api/v1.0/app/core/auth/login/otp';
}
export function loginwithotpverifyapi()
{
    return domain() + '/api/v1.0/app/core/auth/login/otp/verify';
}
export function signupapi()
{
    return domain() + "/api/v1.0/app/core/auth/signup";
}
export function signupverifyapi()
{
    return domain() + '/api/v1.0/app/core/auth/verify-otp';
}
export function forgotpasswordsendotpapi()
{
    return domain() + '/api/v1.0/app/core/auth/forgot/password';
}
export function forgotpasswordotpverifyapi()
{
    return domain() + '/api/v1.0/app/core/auth/forgot/password/verify-otp';
}
export function forgotresetpasswordapi()
{
    return domain() + '/api/v1.0/app/core/auth/reset/password';
}