import React,{useEffect} from 'react';
import Logincombinecomponents from './authpages/logincombinecomponents';
import { BrowserRouter as Router, Routes, Route , useLocation,} from 'react-router-dom';
import './App.css';
import Forgotpage from './authpages/forgotpage/forgotpage';
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
      // makeAjaxCall();
    }, [pathname]);
  
    return null;
  }
  return (
   <>
   <Router>
        <ScrollToTop/>
          <Routes>
           <Route exact path='/' element={<Logincombinecomponents/>}/>
           <Route  path='/auth/forgot' element={<Forgotpage/>}/>
           </Routes>
           </Router>
   </>
  );
}

export default App;
