export const Error401 = () => {
    const lod4 = extractLoginUser();
    window.location.href = `/refresh?X-Authuser=${encodeURIComponent(lod4)}`;
    return lod4;
}
export const extractLoginUser = () => {
    const routePath = window.location.pathname;
    const regex = /\/u\/([^/]+)/; // Match the last part after the last slash
  
    const match = routePath.match(regex);
    if (match) {
        return match[1]; // Return the matched value
    }
    return null; // Return null if no match is found
}  
export const handleApiError = (error, setIsLoading = null, setError422 = null, handle422AsToast = true, showErrorToast) => {
    if (typeof showErrorToast !== 'function') {
      console.error('showErrorToast must be a function');
      return;
    }
   
    if (error.response) {
      const { status, data } = error.response;
  
      switch (status) {
        case 401:
          const errorCode = data.error_code;
          if (errorCode === 'token_expired' || errorCode === 'already_logged_out') {
            Error401(); // Handle unauthorized errors
          } else {
            showErrorToast(data.mobile_error || 'Unauthorized access.', '401Error');
          }
          break;
  
        case 422:
          if (handle422AsToast) {
            showErrorToast(data.mobile_error || 'Validation error.', '422Error');
          } else if (typeof setError422 === 'function') {
            setError422(data.mobile_error || 'Validation error.');
          }
          break;
  
        case 500:
          showErrorToast(data.mobile_error || 'Internal server error.', '500Error');
          break;
  
        default:
          showErrorToast(data.mobile_error || 'An unexpected error occurred.','UnexpectedError');
          break;
      }
    } else {
      showErrorToast('Network error or server is unreachable.', 'NetworkError');
    }
  
    if (typeof setIsLoading === 'function') {
      setIsLoading(false);
    }
};

export const redirectToWithAuthUser = (redirectUrl, X_AuthUser) => {
    const queryParams = {
      X_AuthUser: X_AuthUser
    };
    redirectTo(redirectUrl, queryParams);
}

export const mergeAllQueryParameters = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
  
    // Extract and store the value of the 'redirecturl' parameter
    const redirectUrl = urlParams.get('redirecturl');
  
    // Create an array of entries, excluding the 'redirecturl' parameter
    const filteredParams = Array.from(urlParams.entries())
      .filter(([key]) => key !== 'redirecturl') // Filter out the 'redirecturl' parameter
      .map(([key, value]) => `${key}=${value}`) // Format the parameters
      .join('&'); // Join them with '&'
      
    const result = redirectUrl ? `${redirectUrl}${filteredParams}` : filteredParams;
    return result;
};
  
function redirectTo(url, params) {
    const queryString = Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&');
    const redirectUrl = `${url}?${queryString}`;
    window.location.href = redirectUrl;
}

export const getMaskedEmail = (email) => {
    if (!email) {
      return null;
    }
    const [username, domain] = email.split('@');
    const maskedUsername = username.charAt(0) + '*'.repeat(username.length - 1);  
    return `${maskedUsername}@${domain}`;
};

export const getMaskedPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
      return null;
    }
  
    const lastTwoDigits = phoneNumber.slice(-2);
  
    return `**********${lastTwoDigits}`;
};

export const get_url_params = () => {
  const current = window.location.href;
  const urlObject = new URL(current);
  const searchParams = new URLSearchParams(urlObject.search);
  const params = {};  // Initialize an empty object to store key-value pairs
  
  // Iterate over search parameters and add them to the params object
  searchParams.forEach((value, key) => {
    params[key] = value;  // Store the key-value pair in the object
  });

  return params;
}

export const process_params = () => {
  // Get the URL parameters from the get_url_params function
  const params = get_url_params();

  // Extract the 'redirecturl' param value and remove it from the params object
  const redirecturlValue = params.redirecturl ? `redirecturl=${params.redirecturl}` : '';
  const redirecturl = params.redirecturl ?  `${params.redirecturl}` : ''
  const is_auth_check = params.is_auth_check ?  `${params.is_auth_check}` : ''
  delete params.redirecturl;  // Remove 'redirecturl' key from params

  // Remove 'is_auth_check' key from params
  delete params.is_auth_check;

  // Extract the first parameter key and its value
  // const firstKey = Object.keys(params)[0];
  // const firstValue = params[firstKey] || '';

  // // Remove the first key from the params object
  // delete params[firstKey];

  // Combine remaining parameters into a query string
  const otherParamsString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');

  // Construct the final 'allparams' string with 'redirecturl' first, then firstValue (without its key), followed by other parameters
  const allparams = [
    redirecturlValue,
    // firstValue,
    otherParamsString
  ].filter(part => part).join('&');

  return {
    redirecturl: redirecturl,
    is_auth_check:is_auth_check,
    allparams: allparams 
  };
}
