import React,{useState,useCallback,useEffect,memo} from 'react';
import google from '../images/google-01.png';
import facebook from '../images/facebook-01.png';
import twitter from '../images/Twitter X.png';
import logo from '../images/evzone-logo-01.png';
import Loginwithpassword from './loginwithpassword';
import Loginwithotp from './loginwithotp';
import Signup from './signup';
import axiosInstance from '../api/axiosinstance';
import { sidapi } from '../api/url';
import {process_params } from '../api/commonfunctions/common';
import { myaccountdomain } from '../api/url';
const sidapiurl = sidapi();
const myaccountdomainurl = myaccountdomain();
const Logincombinecomponents = memo(()=>{
  const [items, setItems] = useState(["back1", "back2", "back3", "back4", "back5"]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [signupclick,setSignupclicked] = useState(false);
  const [showForm1,setShowForm1] = useState(true);
  const [showForm2,setShowForm2] = useState(false);
  const [showForm3,setShowForm3] = useState(false);
  const [isLoading4,setIsLoading4] = useState(false);
  const autoplayInterval = 15000;
  const nextSlide = useCallback(() => {
    setItems((prev) => [...prev, prev[currentIndex]]);
    setCurrentIndex((prevIndex) => prevIndex + 1);
  }, [currentIndex]);
  useEffect(() => {
    const intervalId = setInterval(nextSlide, autoplayInterval);
    return () => clearInterval(intervalId);
  }, [nextSlide, autoplayInterval]);
  const sid = async() => {
     try{
        const response = await axiosInstance.get(sidapiurl);
        if(response.status === 200){
          const userdata = response.data.data;
          if(userdata.length > 0){
            const params = process_params(); 
             if(params.is_auth_check === 'global_sign'){
                return;
             }else{
             if(params.redirecturl){
              const urlObj = new URL(params.redirecturl);
              urlObj.searchParams.set('X_AuthUser', 0);
              window.location.href = urlObj.toString();
              // const all = params.allparams;
              // let updatedall = all.replace("redirecturl=", '')
              // let updateurl = updatedall.replace("&",'?');             
              // window.location.href = updateurl;
             }else{              
              //  window.location.href = "https://myaccount.evzone.app/u/0/services"
              window.location.href = `${myaccountdomainurl}/u/0/services`
             }
            }
          }
        }
     }catch(error){
        return;
     }
  }
  useEffect(()=>{
    sid();
  },[])
  const signform = (event) => {
    if(event) event.preventDefault();
     setShowForm1(true);
     setSignupclicked(false);
     setShowForm2(false);
     setShowForm3(false);
  }
  const signupform = (event) => {
    if(event) event.preventDefault();
    setShowForm2(true);
    setSignupclicked(true);
    setShowForm1(false);
    setShowForm3(false);
  }
  const toggleLoginForms = (event) => {
    if(event) event.preventDefault();
    setShowForm3(true);
    setSignupclicked(false);
    setShowForm1(false);
    setShowForm2(false);
  }
  return (
    <>
    {isLoading4 && (
                <>   
                    <div className="loaderover">
                    <div className="loader ">
                    </div>
                    </div>
                </>
            )} 
       <div className="slider-wrapper_login">
       <div
          className="slider-content"
          style={{
            display:'flex',
            transform: `translateX(-${((currentIndex + 1) * 100)}%)`,
            transition: "transform 0.5s ease-in-out",
            willChange: "transform",
            width: '100%',
          }}
        >
          {items.map((item, index) => (
            <div key={index} className={`bannerimg ${item}`}></div>
          ))}
          </div>
          <div className="refresh_div221">
           <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',minHeight:'100vh'}}> 
        <div>
        <img src={logo}  alt="logo" className="logo" />
        </div>
        <form className="refresh_form">
        <div className="refresh_div1">
        <h6>EVzone</h6>
        <div className="loggedbuttonns_div">
            <button className={`loggedbuttonsign ${showForm1 || showForm3 ?'loggedbuttonsign-active' : ''}`} onClick={signform}>Sign In</button>
            <button className={`loggedbuttonsign ${showForm2?'loggedbuttonsign-active' : ''}`} onClick={signupform}>Sign Up</button>
        </div>
        {showForm1 && (
          <Loginwithpassword showForm1={showForm1} setShowForm1={setShowForm1} showForm2={showForm2} setShowForm2={setShowForm2} setShowForm3={setShowForm3}  toggleLoginForms={toggleLoginForms}  setIsLoading4={setIsLoading4} myaccountdomainurl={myaccountdomainurl}/>
        )}
        {showForm2 && (
          <Signup showForm1={showForm1} setShowForm1={setShowForm1} showForm2={showForm2} setShowForm2={setShowForm2} setShowForm3={setShowForm3}   setIsLoading4={setIsLoading4} signupclick={signupclick} myaccountdomainurl={myaccountdomainurl}/>
        )}
        {showForm3 && (
          <Loginwithotp showForm1={showForm1} setShowForm1={setShowForm1} showForm2={showForm2} setShowForm2={setShowForm2} showForm3={showForm3} setShowForm3={setShowForm3}  toggleLoginForms={signform}  setIsLoading4={setIsLoading4} myaccountdomainurl={myaccountdomainurl}/>
        )} 
        </div>
        <div className=" pt-3 fw-800 text-light text-center">
                    <p>Sign in with</p>
                  {/* <button onClick={saa}>asas</button> */}
                </div>
                <div className=' login-icons'>
                    {/* <div className='col-4 col-md-2 '> */}
                    {/* <img src={google} alt='google'   style={{ width: "45px", height: 'auto', color: "white", border: '1px solid white', backgroundColor: "rgb(20, 212, 148)", borderRadius: '5px', padding: '8px',marginRight:'10px' }} ></img> */}
                        <img src={google} alt='google'   style={{ width: "45px", height: 'auto', color: "white", border: '1px solid white', backgroundColor: "rgb(20, 212, 148)", borderRadius: '5px', padding: '8px',marginRight:'10px' }} ></img>
                    {/* </div> */}
                    {/* <div className='col-4 col-md-2'> */}
                        <img src={facebook} alt='google' style={{ width: "45px", height: 'auto', color: "white", border: '1px solid white', backgroundColor: "rgb(20, 212, 148)", borderRadius: '5px', padding: '8px',marginRight:'10px' }} ></img>
                    {/* </div> */}
                    {/* <div className='col-4 col-md-2'> */}
                        <img src={twitter} alt='google' style={{ width: "45px", height: 'auto', color: "white", border: '1px solid white', backgroundColor: "rgb(20, 212, 148)", borderRadius: '5px', padding: '8px' }} ></img>
                    {/* </div> */}
                </div>
        </form>
        </div>
        </div>
       </div>
       
    </>
  )
})

export default Logincombinecomponents